import React from 'react';
import BackgroundImage, { IFluidObject } from 'gatsby-background-image';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';

import styles from './Hero.module.scss';

interface HeroProps {
  bgImageFluid: IFluidObject;
  title: string;
  subtitle?: string;
  suptitle?: string;
  withLogo?: boolean;
}

const Hero = ({
  bgImageFluid,
  title,
  subtitle,
  suptitle,
  withLogo = false,
}: HeroProps): React.ReactElement => {
  const data = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "logo-main.png" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
    }
  `);
  return (
    <section className={'hero-section ' + styles.container}>
      <BackgroundImage className={styles.bgImage} fluid={bgImageFluid}>
        <div className={styles.titlesWrapper}>
          {/* {withLogo && <Img fixed={data.logo.childImageSharp.fixed} className={styles.logoImage} />} */}
          {suptitle && <h5>{suptitle}</h5>}
          <h1>{title}</h1>
          {subtitle && <h4>{subtitle}</h4>}
        </div>
        <div className={styles.overlay}></div>
      </BackgroundImage>
    </section>
  );
};

export default Hero;
